import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Button, Icon, Input, Message } from "../../atoms/index";
import {
  addInventoryService,
  deleteInventoryService,
  getInventoriesService,
  updateInventoryService,
} from "../../services/inventory";
import { deleteProduct, genericFilter, searchElement } from "../../utils/index";
import "./inventory.style.scss";
import { InventoryTable } from "./inventoryTable";

const initialState = {
  code: "",
  nameProduct: "",
  amount: 0,
  purchasePrice: "",
  salePrice: "",
  offerPrice: 0,
  offerAmount: 0,
};

export const Inventory = ({ title }) => {
  const [inventory, setInventory] = useState(initialState);
  const [currentAmount, setCurrentAmount] = useState(0);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [message, setMessage] = useState({});
  const [showModalDeleteProduct, setShowModalDeleteProduct] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);

  const consultProducts = (products) => {
    const sortedProducts = products.sort(
      (productA, productB) => productA.code - productB.code
    );
    setProducts(sortedProducts);
    setFilteredProducts(sortedProducts);
  };

  useEffect(() => {
    const loadInventory = async () => {
      consultProducts(await getInventoriesService());
    };
    loadInventory();
  }, []);

  const handleSave = async () => {
    setMessage({});
    const getProduct = searchElement({
      code: inventory.code,
      items: products,
      key: "code",
    });
    if (getProduct.length > 0) {
      const newAmount = getProduct[0].amount
        ? parseInt(getProduct[0].amount) + parseInt(currentAmount)
        : parseInt(currentAmount);
      const response = await updateInventoryService({
        ...inventory,
        amount: newAmount,
      });

      if (response.status === 200) {
        const { code } = response.product;
        const indexReplace = products.findIndex(
          (product) => product.code === code
        );
        products[indexReplace] = response.product;
        const newProducts = () => {
          const updatedProducts = [...products];
          updatedProducts[indexReplace] = {
            ...updatedProducts[indexReplace],
            ...response.product,
          };
          return updatedProducts;
        };
        setProducts(newProducts);
        setFilteredProducts(
          genericFilter({
            elements: products,
            conditions: response.product,
          })
        );
        setCurrentAmount("");
        setMessage({
          title: "Producto actualizado correctamente",
          severity: "success",
        });
      } else {
        setMessage({
          title: "No fue posible actualizar el Producto",
          severity: "error",
        });
      }
    } else {
      const newAmount = inventory.amount
        ? parseInt(inventory.amount) + parseInt(currentAmount)
        : parseInt(currentAmount);
      const response = await addInventoryService({
        ...inventory,
        amount: newAmount,
      });
      if (response === 200) {
        setMessage({
          title: "Producto adicionado correctamente",
          severity: "success",
        });
        const newAmount = inventory.amount
          ? parseInt(inventory.amount) + parseInt(currentAmount)
          : parseInt(currentAmount);
        consultProducts([...products, { ...inventory, amount: newAmount }]);
        setCurrentAmount("");
      } else {
        setMessage({
          title: "No fue posible adicionar el Producto",
          severity: "error",
        });
      }
    }
    setInventory(initialState);
  };

  const handleGet = async (infoProduct) => {
    setMessage({});
    let specificValues = false;
    if (infoProduct.code) {
      specificValues = true;
    }
    const filterProducts = genericFilter({
      elements: products,
      conditions: infoProduct,
      specificValues,
    });
    if (filterProducts.length > 0) {
      setFilteredProducts(filterProducts);
    } else {
      setMessage({
        title: "Producto no encontrado",
        severity: "error",
      });
      setFilteredProducts([]);
    }
  };

  const handleDeleteProduct = async (product) => {
    setMessage({});
    const result = await deleteInventoryService(product.code);
    if (result === 200) {
      consultProducts(
        deleteProduct({
          productCode: product.code,
          products,
        })
      );
      setMessage({
        title: "Producto eliminado correctamente",
        severity: "success",
      });
      setInventory(initialState);
    } else {
      setMessage({
        title: "No fue posible eliminar el Producto",
        severity: "error",
      });
    }
  };

  const handleEditProduct = (product) => {
    setMessage({});
    setInventory({ ...product });
    setCurrentAmount(0);
  };

  const validQuantity = (key, value) => {
    if (value < 0) {
      setMessage({
        title: "Ingrese un valor igual o mayor a 0",
        severity: "error",
      });
    } else {
      setMessage({});
      setInventory({
        ...inventory,
        [key]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="inventory">
        <h1 className="inventory--h1">{title}</h1>
        <section className="inventory--content--info">
          <section className="inventory--info">
            <Input
              autoFocus
              title="Código"
              type="number"
              value={inventory?.code}
              onChange={(e) =>
                setInventory({ ...inventory, code: e.target.value })
              }
            />
            <Input
              title="Nombre"
              type="text"
              maxLength="30"
              value={inventory?.nameProduct}
              onChange={(e) =>
                setInventory({ ...inventory, nameProduct: e.target.value })
              }
            />
            <NumericFormat
              thousandSeparator
              customInput={Input}
              title="Cantidad ingresada"
              value={currentAmount}
              placeholder="0"
              onValueChange={({ floatValue }) => {
                const intValue = floatValue ?? 0;
                if (intValue < 0) {
                  setMessage({
                    title: "Ingrese una cantidad igual o mayor a 0",
                    severity: "error",
                  });
                } else {
                  setMessage({});
                  setCurrentAmount(intValue);
                }
              }}
            />
            <Input
              title="Cantidad actual"
              type="number"
              value={inventory?.amount}
              disabled
            />
            <NumericFormat
              thousandSeparator
              customInput={Input}
              title="Precio compra"
              placeholder="0"
              value={inventory?.purchasePrice}
              onValueChange={({ floatValue }) =>
                validQuantity("purchasePrice", floatValue)
              }
            />
            <NumericFormat
              thousandSeparator
              customInput={Input}
              title="Precio venta"
              placeholder="0"
              value={inventory?.salePrice}
              onValueChange={({ floatValue }) => {
                validQuantity("salePrice", floatValue);
              }}
            />
            <NumericFormat
              thousandSeparator
              customInput={Input}
              title="Precio oferta"
              placeholder="0"
              value={inventory?.offerPrice}
              onValueChange={({ floatValue }) => {
                validQuantity("offerPrice", floatValue);
              }}
            />
            <NumericFormat
              thousandSeparator
              customInput={Input}
              title="Cantidad oferta"
              placeholder="0"
              value={inventory?.offerAmount}
              onValueChange={({ floatValue }) => {
                validQuantity("offerAmount", floatValue);
              }}
            />
          </section>
          <section className="inventory--info">
            <section className="inventory--content--button">
              <Button
                label="Guardar"
                typeButton="outlined"
                iconName="Save"
                color="success"
                type="submit"
                disabled={
                  inventory?.code === "" ||
                  inventory?.nameProduct === "" ||
                  inventory?.purchasePrice <= 0 ||
                  inventory?.purchasePrice === "" ||
                  inventory?.purchasePrice === undefined ||
                  inventory?.salePrice <= 0 ||
                  inventory?.salePrice === "" ||
                  inventory?.salePrice === undefined ||
                  currentAmount < 0 ||
                  currentAmount === "" ||
                  currentAmount === undefined
                }
                onClick={() => handleSave()}
              />
              <Button
                label="Consultar"
                typeButton="outlined"
                iconName="Search"
                color="success"
                onClick={() =>
                  handleGet({
                    code: inventory?.code,
                    nameProduct: inventory?.nameProduct,
                  })
                }
              />
            </section>
          </section>
          <Message severity={message.severity} label={message.title} />
          <InventoryTable
            products={filteredProducts}
            onDeleteProduct={(product) => {
              setCurrentProduct(product);
              setShowModalDeleteProduct(true);
            }}
            onEditProduct={handleEditProduct}
          />
        </section>
      </form>
      <Modal open={showModalDeleteProduct} className="modalDeleteProduct">
        <section className="modalDeleteProduct--container">
          <section className="modalDeleteProduct--container--title">
            <h1>Eliminar producto</h1>
            <span
              className="modalDeleteProduct--container--icon-back"
              onClick={() => setShowModalDeleteProduct(false)}
            >
              <Icon color="#FFFFFF" name="X"></Icon>
            </span>
          </section>
          <section className="modalDeleteProduct--container--body">
            ¿Está seguro que desea eliminar el producto{" "}
            <b>{currentProduct?.nameProduct}</b>?
          </section>
          <section className="modalDeleteProduct--footer">
            <Button
              label="Cancelar"
              typeButton="outlined"
              iconName="X"
              color="success"
              iconColor="green"
              onClick={() => setShowModalDeleteProduct(false)}
            />
            <Button
              label="Eliminar"
              typeButton="outlined"
              iconName="Trash"
              color="error"
              iconColor="red"
              onClick={() => {
                setShowModalDeleteProduct(false);
                handleDeleteProduct(currentProduct);
              }}
            />
          </section>
        </section>
      </Modal>
    </>
  );
};
