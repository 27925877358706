import { useState } from "react";
import { pages } from "./constans/constants";
import Template from "./templates/template";
import {
  Image,
  View,
  useTheme,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import logo from "./assets/image/distribuidora-san-jose-abarrotes.png";
import "@aws-amplify/ui-react/styles.css";
import { Bills, Inventory, Order, Sales, Welcome } from "./organism/index";
import "./configurationLenguage";
import "./App.style.scss";

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Mi Tienda Online" src={logo} />
      </View>
    );
  },
};

function App({ signOut, user }) {
  const { bills, inventory, orders, sales, welcome } = pages;

  const [children, setChildren] = useState(welcome);
  const renderComponent = (children) => {
    switch (children) {
      case inventory:
        return <Inventory title="Administración de inventario" />;
      case sales:
        return <Sales title="Administración de ventas" />;
      case orders:
        return (
          <Order
            title="Crear nueva orden"
            user={user}
            setChildren={setChildren}
          />
        );
      case bills:
        return <Bills title="Administración de gastos" user={user} />;
      default:
        return <Welcome user={user} />;
    }
  };
  return (
    <>
      <Template
        setChildren={setChildren}
        activeButton={children}
        signOut={signOut}
        user={user}
      >
        {renderComponent(children)}
      </Template>
    </>
  );
}

export default withAuthenticator(App, { hideSignUp: true, components });
