import React from "react";
import "./welcome.style.scss";

export const Welcome = ({ user }) => {
  return (
    <section className="welcome">
      <h1 className="welcome--h1">Distribuidora San Jose de Abarrotes</h1>
      <h2 className="welcome--h2">¡Bienvenid@ {user.attributes.email}!</h2>
      <img
        className="welcome--img"
        src="image/distribuidora-san-jose-abarrotes.png"
        alt="Carrito Distribuidora San Jose de Abarrotes"
      />
    </section>
  );
};
