import axios from "axios";

export const addOrderService = async ({ id, products, totalOrder, seller }) => {
  const URL =
    "https://5tz4k4kc8h.execute-api.us-east-1.amazonaws.com/v1/orders";
  const result = await axios.post(URL, {
    id,
    products,
    totalOrder,
    seller,
  });
  return result;
};
