import { Modal } from "@mui/material";
import React, { useRef } from "react";
import Barcode from "react-barcode";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { Button, Icon } from "../../atoms";
import { pages } from "../../constans/constants";
import { setNumberActualOrder } from "../../store";
import "./orderNumber.style.scss";

export const OrderNumber = ({
  title,
  opened,
  orderNumber,
  onClick,
  user,
  setChildren,
}) => {
  const orderNumberPrint = useRef();
  const dispatch = useDispatch();
  const handlePrint = useReactToPrint({
    content: () => orderNumberPrint.current,
  });

  const handleSales = () => {
    dispatch(setNumberActualOrder(orderNumber));
    setChildren(pages.sales);
  };

  return (
    <Modal className="ModalOrderNumber" open={opened}>
      <section className="ModalOrderNumber--container">
        <section className="ModalOrderNumber--container--title">
          <h1>{title}</h1>
          <span
            className="ModalOrderNumber--container--icon-back"
            onClick={onClick}
          >
            <Icon name="X" color="#FFFFFF"></Icon>
          </span>
        </section>
        <hr />
        <section className="ModalOrderNumber--container--content--info">
          <Barcode ref={orderNumberPrint} value={orderNumber} fontSize={50} />
          <section>
            <Button
              label="Imprimir"
              typeButton="outlined"
              iconName="Printer"
              color="success"
              onClick={handlePrint}
            />
          </section>

          {user.attributes.profile === "ADMIN" ? (
            <section>
              <Button
                label="Ir a pagar"
                typeButton="outlined"
                iconName="DollarSign"
                color="success"
                onClick={() => handleSales()}
              />
            </section>
          ) : (
            <></>
          )}
        </section>
      </section>
    </Modal>
  );
};
