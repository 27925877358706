export const searchElement = ({ code, items, key }) => {
  return items.filter((element) => element[key] === code);
};

export const deleteProduct = ({ productCode, products }) => {
  return products.filter(({ code }) => code !== productCode);
};

export const addProductToOrder = ({
  code,
  products,
  amountOrder,
  inventory,
}) => {
  const productInventory = inventory.find((product) => product.code === code);

  if (!productInventory) {
    return {
      newProducts: products,
      title: "Producto no existe en el inventario",
      severity: "error",
    };
  }

  const indexProductTable = products.findIndex(
    (product) => product.code === code
  );

  if (indexProductTable === -1) {
    if (productInventory.amount < amountOrder) {
      return {
        newProducts: products,
        title: `No hay cantidades suficientes en el inventario. Cantidad actual:${productInventory.amount}`,
        severity: "error",
      };
    }

    return {
      newProducts: [
        ...products,
        {
          ...productInventory,
          amountOrder: parseInt(amountOrder),
        },
      ],
      title: "producto adicionado correctamente",
      severity: "success",
    };
  }
  const updatedProducts = [...products];
  const newAmount =
    products[indexProductTable].amountOrder + parseInt(amountOrder);
  if (productInventory.amount < newAmount) {
    return {
      newProducts: products,
      title: "No hay cantidades suficientes en el inventario",
      severity: "error",
    };
  }
  updatedProducts[indexProductTable] = {
    ...updatedProducts[indexProductTable],
    amountOrder: newAmount,
  };
  return {
    newProducts: updatedProducts,
    title: "Producto adicionado correctamente",
    severity: "success",
  };
};

export const genericFilter = ({
  elements,
  conditions,
  specificValues = true,
}) =>
  elements.filter((element) => getMatch(element, conditions, specificValues));

export const getMatch = (element, conditions, specificValues) => {
  return Object.keys(conditions).every((key) => {
    if (specificValues) {
      if (conditions[key] !== "") {
        return element.hasOwnProperty(key) && element[key] === conditions[key];
      }
    } else {
      if (conditions[key] !== "") {
        return (
          element.hasOwnProperty(key) &&
          element[key].toLowerCase().includes(conditions[key].toLowerCase())
        );
      }
    }
    return true;
  });
};
