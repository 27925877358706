import React from "react";
import { Alert } from "@mui/material";

export const Message = ({ label, severity }) => {
  return (
    label && (
      <Alert sx={{ m: 1 }} key={label} severity={severity}>
        {label}
      </Alert>
    )
  );
};
