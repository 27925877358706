import { Modal } from "@mui/material";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { format } from "../../utils";
import { Button } from "../button/button";
import { Icon } from "../icon/icon";
import { Message } from "../message/message";
import "./print.style.scss";

export const Print = ({ order, sales, opened, onClick }) => {
  const orderPrint = useRef();
  const handlePrint = useReactToPrint({
    content: () => orderPrint.current,
    onAfterPrint: () => {
      onClick();
    },
  });

  const getDataProduct = (product) => {
    if (product.offerAmount && product.offerPrice) {
      const offerProducts = Math.floor(
        product.amountOrder / product.offerAmount
      );
      const noOfferProducts = product.amountOrder % product.offerAmount;
      const totalOffer =
        offerProducts * product.offerPrice * product.offerAmount;
      const totalNoOffer = noOfferProducts * product.salePrice;
      return (
        <section>
          <p>
            <label>Cant: {noOfferProducts}</label>
            <label>
              {" "}
              x ${format.format(product.salePrice)} ={" "}
              {format.format(totalNoOffer)}
            </label>
          </p>
          <p>
            <label>Cant: {offerProducts * product.offerAmount}</label>
            <label>
              {" "}
              x ${format.format(product.offerPrice)} ={" "}
              {format.format(totalOffer)}
            </label>
          </p>
          <label> Total: {totalNoOffer + totalOffer}</label>
        </section>
      );
    } else {
      return (
        <section>
          <label>Cant: {product.amountOrder}</label>
          <label>
            {" "}
            x ${format.format(product.salePrice)} ={" "}
            {format.format(product.amountOrder * product.salePrice)}
          </label>
        </section>
      );
    }
  };
  return (
    <Modal open={opened} id="ModalBill" key="ModalBill" className="ModalBill">
      <section className="ModalBill--container">
        <head>
          <h1>Imprimir Factura</h1>
          <span className="ModalBill--closeButton" onClick={onClick}>
            <Icon name="X" color="#FFFFFF" />
          </span>
        </head>
        <body>
          <section ref={orderPrint}>
            <section className="ModalBill--print">
              <label>________________________________</label>
              <label>VALENTINA RAMIREZ</label>
              <label>DISTRIBUIDORA SAN JOSE DE ABARROTES</label>
              <label>NIT: 1152703196-5</label>
              <label>Dirección: CR 51 N° 56-42</label>
            </section>
            <section className="ModalBill--print">
              <label>Tipo de regimes: Regimen Simplificado</label>
              <label>Res: 0 Fecha: {order.createDate} </label>
            </section>
            <section className="ModalBill--print">
              <label>==========ORIGINAL==========</label>
            </section>
            <section className="ModalBill--print">
              <label>Detalles de factura</label>
              {order?.products?.map((product) => {
                return (
                  <>
                    <section>
                      <label>{product.nameProduct}</label>
                    </section>
                    {getDataProduct(product)}
                  </>
                );
              })}
            </section>
            <section className="ModalBill--print">
              <label>
                Total factura:{"$" + format.format(order.totalOrder)}
              </label>
              <label>
                Total recibido:{"$" + format.format(sales.valueReceived)}
              </label>
              <label>
                Total cambio:
                {"$" +
                  format.format(
                    sales.valueReceived
                      ? sales.valueReceived - order.totalOrder
                      : 0
                  )}
              </label>
            </section>
            <section className="ModalBill--print">
              <label>========FORMA DE PAGO========</label>
              <label>
                {sales.paymentMethod}:{"$" + format.format(order.totalOrder)}{" "}
              </label>
            </section>
          </section>
        </body>
        <Message severity="success" label="El pago se realizo correctamente" />
        <section className="ModalBill--button">
          <Button
            label="Imprimir"
            typeButton="outlined"
            iconName="Printer"
            color="success"
            onClick={handlePrint}
          />
        </section>
      </section>
    </Modal>
  );
};
