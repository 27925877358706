import React from "react";
import * as FIcon from "react-feather";
import "./icon.style.scss";

const defaultColor = "#2e7d32";

export const Icon = ({ name, color = defaultColor, size = 20 }) => {
  return (
    <i className="icon">{React.createElement(FIcon[name], { color, size })}</i>
  );
};
