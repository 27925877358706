import axios from "axios";

export const addInventoryService = async ({
  code,
  nameProduct,
  amount,
  purchasePrice,
  salePrice,
  offerAmount,
  offerPrice,
}) => {
  const URL =
    "https://uef6z41imf.execute-api.us-east-1.amazonaws.com/v1/product";
  const result = await axios.post(URL, {
    code,
    nameProduct,
    amount,
    purchasePrice,
    salePrice,
    offerAmount,
    offerPrice,
  });
  return result.data.status;
};
