import React from "react";
import { Button as Buttons } from "@mui/material";
import { Icon } from "../index";
import classNames from "classnames";
import "./button.style.scss";

export const Button = ({
  label,
  color,
  typeButton,
  iconName,
  iconColor,
  classes,
  type,
  ...resProps
}) => {
  return (
    <Buttons
      color={color}
      startIcon={<Icon name={iconName} color={iconColor} />}
      variant={typeButton}
      type={type}
      className={classNames("button", classes)}
      {...resProps}
    >
      {label}
    </Buttons>
  );
};
