import React, { useCallback, useState } from "react";
import { Button } from "../../atoms";
import { format } from "../../utils/index";
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  Paper,
  TablePagination,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2e7d32",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export function InventoryTable({
  products = [],
  onEditProduct,
  onDeleteProduct,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditProduct = useCallback(
    (product) => () => onEditProduct(product),
    [onEditProduct]
  );
  const handleDeleteProduct = useCallback(
    (product) => () => onDeleteProduct(product),
    [onDeleteProduct]
  );
  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>Código</StyledTableCell>
              <StyledTableCell align="right">Nombre</StyledTableCell>
              <StyledTableCell align="right">Cantidad total</StyledTableCell>
              <StyledTableCell align="right">Precio compra</StyledTableCell>
              <StyledTableCell align="right">Precio venta</StyledTableCell>
              <StyledTableCell align="right">Precio oferta</StyledTableCell>
              <StyledTableCell align="right">Cantidad oferta</StyledTableCell>
              <StyledTableCell align="center">Editar</StyledTableCell>
              <StyledTableCell align="center">Eliminar</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((product) => {
                return (
                  <TableRow key={product.code}>
                    <StyledTableCell component="th" scope="row">
                      {product.code}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {product.nameProduct}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {format.format(product.amount)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      ${format.format(product.purchasePrice)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      ${format.format(product.salePrice)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      ${format.format(product.offerPrice)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {format.format(product.offerAmount)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Button
                        iconName="Edit3"
                        onClick={handleEditProduct(product)}
                      ></Button>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Button
                        onClick={handleDeleteProduct(product)}
                        iconName="Trash2"
                      ></Button>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 50]}
        component="div"
        count={products.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
