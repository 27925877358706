import React from "react";
import { Button } from "../../atoms/index";
import "./header.style.scss";

export const Header = ({ signOut, setChildren }) => {
  const handleClick = (children) => {
    setChildren(children);
  };
  return (
    <header className="header">
      <figure className="header--figure">
        <img
          className="header--img"
          src="image/distribuidora-san-jose-abarrotes.png"
          alt="Distribuidora San Jose de Abarrotes"
          onClick={() => handleClick("Welcome")}
        />
        <h2 className="header--h2" onClick={() => handleClick("Welcome")}>
          Distribuidora San Jose de Abarrotes
        </h2>
      </figure>
      <section className="header-button">
        <Button
          label="Cerrar sesión"
          typeButton="outlined"
          iconName="LogOut"
          color="success"
          onClick={signOut}
        />
      </section>
    </header>
  );
};
