import React, { useState } from "react";
import { Modal } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Input, Button, Select, Icon, Message } from "../../atoms/index";
import { SalesHistoryTable } from "./salesHistoryTable";
import "./salesHistory.style.scss";
import { getOrdersService } from "../../services/order";

export const SalesHistory = ({ title, opened, onClick, onDetailProduct }) => {
  const [ordersFilter, setOrdersFilter] = useState([]);
  const [salesHistory, setSalesHistory] = useState({});
  const [message, setMessage] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = async () => {
    onClick();
    setSalesHistory({});
    setOrdersFilter([]);
  };

  const handleConsult = async () => {
    setMessage({});
    setOrdersFilter([]);
    if (
      (!salesHistory.createDate || salesHistory.createDate === "") &
      (!salesHistory.id || salesHistory.id === "") &
      (!salesHistory.stateOrder || salesHistory.stateOrder === "")
    ) {
      setMessage({
        title: "Ingrese al menos un criterio de búsqueda",
        severity: "info",
      });
    } else {
      if (isLoading === false) {
        setIsLoading(true);
        const getOrders = await getOrdersService(salesHistory);
        if (getOrders.length === 0) {
          setMessage({
            title: "No hay ventas para la consulta realizada",
            severity: "error",
          });
        }
        setIsLoading(false);
        setOrdersFilter(getOrders);
      }
    }
  };

  return (
    <Modal
      open={opened}
      className="ModalSales"
      id="ModalHistorySales"
      key="ModalHistorySales"
    >
      <section className="ModalSales--container">
        <section className="ModalSales--container--title">
          <h1 className="ModalSales--container--h1">{title}</h1>
          <span
            className="ModalSales--container--icon-back"
            onClick={handleClose}
          >
            <Icon color="#FFFFFF" name="X"></Icon>
          </span>
        </section>
        <hr />
        <section className="ModalSales--container--content--info">
          <section className="ModalSales--container--info">
            <Input
              title="Código"
              type="text"
              value={salesHistory.id}
              onChange={(e) => {
                setSalesHistory({ ...salesHistory, id: e.target.value });
              }}
            />
            <Select
              title="Estado"
              name="select-state"
              value={salesHistory.stateOrder}
              options={[
                { value: "CANCELADA", label: "CANCELADA" },
                { value: "CREADA", label: "CREADA" },
                { value: "PAGADA", label: "PAGADA" },
              ]}
              handleChange={(e) => {
                setSalesHistory({
                  ...salesHistory,
                  stateOrder: e.target.value,
                });
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD/MM/YYYY"
                onChange={(date) => {
                  if (date) {
                    setSalesHistory({
                      ...salesHistory,
                      createDate: `${date.$D}/${date.$M + 1}/${date.$y}`,
                    });
                  } else {
                    setSalesHistory({
                      ...salesHistory,
                      createDate: "",
                    });
                  }
                }}
              />
            </LocalizationProvider>
          </section>
          <section>
            <Button
              label={!isLoading ? "Consultar" : "Consultando Información ..."}
              typeButton="outlined"
              iconName="Search"
              color="success"

              onClick={handleConsult}
            />
          </section>
        </section>
        <section className="ModalSales--container--content--table">
          <Message severity={message.severity} label={message.title} />
          {ordersFilter.length > 0 ? (
            <SalesHistoryTable
              products={ordersFilter}
              onDetailProduct={onDetailProduct}
            />
          ) : (
            <></>
          )}
        </section>
      </section>
    </Modal>
  );
};
