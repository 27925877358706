import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: false,
  errorDetail: {},
  inventory: [],
  isLoading: false,
};

export const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    startLoadingInventoryData: (state) => {
      state.isLoading = true;
    },
    setInventoryData: (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.inventory = action.payload;
    },
    errorInventoryData: (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.errorDetail = action.payload;
    },
    resetInventory: () => initialState,
  },
});

export const {
  startLoadingInventoryData,
  setInventoryData,
  errorInventoryData,
  resetInventory,
} = inventorySlice.actions;
