import axios from "axios";

export const getOrderService = async ({ id }) => {
  const URL = `https://uef6z41imf.execute-api.us-east-1.amazonaws.com/v1/order/${id}`;
  const response = await axios.get(URL);
  if (response.data.status !== 200) {
    return { errorDetail: "Orden no encontrada" };
  }

  return response.data.body.order;
};
