import React, { useCallback, useState } from "react";
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  Paper,
  TablePagination,
} from "@mui/material";
import { Button } from "../../atoms";
import { format } from "../../utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2e7d32",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const BillTable = ({ bills = [], onDeleteProduct }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteProduct = useCallback(
    (product) => () => onDeleteProduct(product),
    [onDeleteProduct]
  );
  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell align="right">Detalle</StyledTableCell>
              <StyledTableCell align="right">Valor</StyledTableCell>
              <StyledTableCell align="right">Fecha</StyledTableCell>
              {onDeleteProduct && (
                <StyledTableCell align="center">Eliminar</StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {bills
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((bill) => {
                return (
                  <TableRow key={bill.id}>
                    <StyledTableCell align="right">
                      {bill.detailBill}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      ${format.format(bill.valueBill)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {bill.createDate}
                    </StyledTableCell>
                    {onDeleteProduct && (
                      <StyledTableCell align="right">
                        <Button
                          onClick={handleDeleteProduct(bill)}
                          iconName="Trash2"
                        ></Button>
                      </StyledTableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={bills.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
