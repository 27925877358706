import React, { useCallback, useState } from "react";
import { format } from "../../utils/index";
import { Button } from "../../atoms";
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  Paper,
  TablePagination,
  FormLabel,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2e7d32",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export function SalesHistoryTable({ products = [], onDetailProduct }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const calculateTotal = (product) => {
    return product.totalOrder;
  };

  const grandTotal = () => {
    const total = products.reduce(
      (total, product) => total + calculateTotal(product),
      0
    );
    return total;
  };

  const handleDetailProduct = useCallback(
    (product) => () => onDetailProduct(product),
    [onDetailProduct]
  );
  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell align="right">Código</StyledTableCell>
              <StyledTableCell align="right">Vendedor</StyledTableCell>
              <StyledTableCell align="right">Estado</StyledTableCell>
              <StyledTableCell align="right">Metodo de pago</StyledTableCell>
              <StyledTableCell align="right">Fecha</StyledTableCell>
              <StyledTableCell align="right">Valor total</StyledTableCell>
              <StyledTableCell align="center">Ver detalle</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((product) => {
                return (
                  <TableRow key={product.id}>
                    <StyledTableCell component="th" scope="row">
                      {product.id}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {product.seller}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {product.stateOrder}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {product.paymentMethod}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {product.createDate}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      ${format.format(product.totalOrder)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Button
                        onClick={handleDetailProduct(product)}
                        iconName="Search"
                      ></Button>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <section className="valueToPayContainer">
          <FormLabel className="valueToPay">TOTAL VENDIDO:</FormLabel>
          <FormLabel className="valueToPay">
            ${format.format(grandTotal())}
          </FormLabel>
        </section>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[4]}
        component="div"
        count={products.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
