import React from "react";
import { TextField } from "@mui/material";

export const Input = ({
  title,
  type,
  disabled,
  rows,
  multiline,
  maxLength,
  onKeyPress,
  ...resProps
}) => {
  return (
    <TextField
      id={title}
      type={type}
      label={title}
      disabled={disabled}
      variant="standard"
      multiline={multiline}
      rows={rows}
      inputProps={{ maxLength, onKeyPress }}
      {...resProps}
    />
  );
};
