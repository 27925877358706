import React from "react";
import { Header, Panel } from "../organism/index";
import "./template.style.scss";

const Template = ({ children, setChildren, activeButton, signOut, user }) => {
  return (
    <section>
      <Header setChildren={setChildren} signOut={signOut} />
      <section className="template">
        <Panel setChildren={setChildren} activeButton={activeButton} user={user} />
        {children}
      </section>
    </section>
  );
};

export default Template;
