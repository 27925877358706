import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: false,
  errorDetail: {},
  orders: [],
  isLoading: false,
};

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    startLoadingOrderData: (state) => {
      state.isLoading = true;
    },
    setOrderData: (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.orders = action.payload;
    },
    errorOrderData: (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.errorDetail = action.payload;
    },
    setNumberActualOrder: (state, { payload }) => {
      state.actualOrder = payload;
    },
  },
});

export const {
  startLoadingOrderData,
  setOrderData,
  errorOrderData,
  setNumberActualOrder,
} = orderSlice.actions;
