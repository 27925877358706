import axios from "axios";

export const updateOrderService = async ({
  id,
  paymentMethod,
  stateOrder,
  products,
}) => {
  const URL = `https://5tz4k4kc8h.execute-api.us-east-1.amazonaws.com/v1/orders`;
  const result = await axios.put(URL, {
    id,
    paymentMethod,
    stateOrder,
    products,
  });
  return result.status;
};
