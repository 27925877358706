import { getBillsService } from "../../../services/bill";
import { errorBillData, setBillData, startLoadingBillData } from "./billSlice";

export const getBills = () => {
  return async (dispatch) => {
    dispatch(startLoadingBillData());
    await getBillsService()
      .then((bill) => {
        dispatch(setBillData(bill));
      })
      .catch((error) => {
        dispatch(errorBillData(error));
      });
  };
};
