export const pages = {
  welcome: "welcome",
  users: "users",
  inventory: "inventory",
  sales: "sales",
  orders: "orders",
  bills: "bills",
};

export const panelButtons = [ 
  {
    label: "Inventario",
    name: "inventory",
    icon: "FileText",
    profile: ["ADMIN"],
  },
  {
    label: "Ventas",
    name: "sales",
    icon: "ShoppingCart",
    profile: ["ADMIN"],
  },
  {
    label: "Orden",
    name: "orders",
    icon: "FilePlus",
    profile: ["ADMIN", "USER"],
  },
  {
    label: "Gastos",
    name: "bills",
    icon: "DollarSign",
    profile: ["ADMIN"],
  },
];
