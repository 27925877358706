import {
  FormLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useState } from "react";
import { format } from "../../utils/index";
import "./sales.style.scss";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2e7d32",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const SaleTable = ({ products = [], setTotalValue }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const calculateTotal = (product) => {
    if (product.offerAmount && product.offerPrice) {
      const offerProducts = Math.floor(
        product.amountOrder / product.offerAmount
      );

      const noOfferProducts = product.amountOrder % product.offerAmount;
      const totalOffer =
        offerProducts * product.offerPrice * product.offerAmount;
      const totalNoOffer = noOfferProducts * product.salePrice;
      return totalOffer + totalNoOffer;
    }
    return product.amountOrder ? product.amountOrder * product.salePrice : 0;
  };

  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>Código</StyledTableCell>
              <StyledTableCell align="right">Nombre</StyledTableCell>
              <StyledTableCell align="right">Cantidad</StyledTableCell>
              <StyledTableCell align="right">Precio unidad</StyledTableCell>
              <StyledTableCell align="right">Precio oferta</StyledTableCell>
              <StyledTableCell align="right">Precio total</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((product) => {
                return (
                  <TableRow key={product.code}>
                    <StyledTableCell component="th" scope="row">
                      {product.code}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {product.nameProduct}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {format.format(product.amountOrder)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      ${format.format(product.salePrice)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      $
                      {format.format(product.offerPrice) +
                        " x " +
                        product.offerAmount}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      ${format.format(calculateTotal(product))}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {setTotalValue && (
          <section className="valueToPayContainer">
            <FormLabel className="valueToPay">VALOR A PAGAR:</FormLabel>
            <FormLabel className="valueToPay">
              ${format.format(setTotalValue)}
            </FormLabel>
          </section>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 50]}
        component="div"
        count={products.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
