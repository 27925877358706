import axios from "axios";

export const updateInventoryService = async ({
  code,
  nameProduct,
  amount,
  purchasePrice,
  salePrice,
  offerAmount,
  offerPrice,
}) => {
  const URL = `https://uef6z41imf.execute-api.us-east-1.amazonaws.com/v1/product/${code}`;
  const result = await axios.put(URL, {
    nameProduct,
    amount,
    purchasePrice,
    salePrice,
    offerAmount,
    offerPrice,
  });
  const body = JSON.parse(result?.data?.body);
  return { status: result.status, ...body };
};
