import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select as Selected,
} from "@mui/material";

export const Select = ({
  title,
  name,
  disabled,
  options,
  handleChange,
  value,
  ...resProps
}) => {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl variant="standard" fullWidth>
        <InputLabel id={name}>{title}</InputLabel>
        <Selected
          labelId={name}
          id={name}
          value={value}
          label={title}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>Seleccione una opción</em>
          </MenuItem>
          {options.map(({ value, label }) => (
            <MenuItem value={value} key={value}>
              {label}
            </MenuItem>
          ))}
        </Selected>
      </FormControl>
    </Box>
  );
};
