import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: false,
  errorDetail: {},
  bills: [],
  isLoading: false,
};

export const billSlice = createSlice({
  name: "bills",
  initialState,
  reducers: {
    startLoadingBillData: (state) => {
      state.isLoading = true;
    },
    setBillData: (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.bills = action.payload;
    },
    errorBillData: (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.errorDetail = action.payload;
    },
    resetBill: () => initialState,
  },
});

export const { startLoadingBillData, setBillData, errorBillData, resetBill } =
  billSlice.actions;
