import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import { Button, Input, Message } from "../../atoms/index";
import { addOrderService } from "../../services/order";
import { getInventories, resetInventory } from "../../store/index";
import {
  addProductToOrder,
  deleteProduct,
  disabledButtonEmptyValues,
} from "../../utils/index";
import { OrderNumber } from "../index";
import { OrderTable } from "./orderTable";
import "./order.style.scss";

const initialState = {
  code: "",
  amount: "",
};

let idOrder = "";

export const Order = ({ title, user, setChildren }) => {
  const id = uuid().split("-")[0].toUpperCase();
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [order, setOrder] = useState(initialState);
  const [products, setProducts] = useState([]);
  const { inventory } = useSelector((state) => state.inventory);
  const [message, setMessage] = useState({});
  const [totalValue, setTotalValue] = useState(0);
  const [isLoading, setIsloading] = useState(false);
  const dispatch = useDispatch();

  const consultProducts = (products) =>
    setProducts(
      products.sort((productA, productB) => productA.code - productB.code)
    );

  const handleAddProduct = async () => {
    setMessage({});
    if (inventory.length === 0) {
      dispatch(getInventories());
    } else {
      const { newProducts, title, severity } = addProductToOrder({
        inventory,
        products,
        amountOrder: order.amount || 1,
        code: order.code,
      });
      setMessage({ title, severity });
      setProducts(newProducts);
      setOrder(initialState);
    }
  };

  const handleCancel = async () => {
    setMessage({});
    setProducts([]);
    setMessage({
      title: "Orden cancelada correctamente",
      severity: "success",
    });
  };

  const handleSaveOrder = async () => {
    setMessage({});
    idOrder = id;

    try {
      setIsloading(true);
      const response = await addOrderService({
        id: id,
        products: products,
        totalOrder: totalValue,
        seller: user.attributes.email,
      });
      if (response?.data?.statuscode === 200) {
        setMessage({
          title: "Orden guardada correctamente",
          severity: "success",
        });
        setProducts([]);
        setShowOrderModal(true);
      } else {
        setMessage({
          title: response?.data?.body,
          severity: "error",
        });
      }
    } catch (error) {
      setMessage({
        title: "No fue posible tramitar la orden",
        severity: "error",
      });
    } finally {
      setIsloading(false);
    }
  };

  const handleProductChange = (code, value) => {
    const index = products.findIndex((product) => product.code === code);
    setProducts(() => {
      const updatedProducts = [...products];
      updatedProducts[index] = {
        ...updatedProducts[index],
        amountOrder: value,
      };
      return updatedProducts;
    });
  };

  const handleDelete = async (product) => {
    setMessage({});
    consultProducts(
      deleteProduct({
        productCode: product.code,
        products,
      })
    );
    setMessage({
      title: "Producto eliminado correctamente",
      severity: "success",
    });
  };

  useEffect(() => {
    if (inventory.length > 0) {
      const { newProducts, title, severity } = addProductToOrder({
        inventory,
        products,
        amountOrder: order.amount || 1,
        code: order.code,
      });
      setMessage({ title, severity });
      setProducts(newProducts);
      setOrder(initialState);
    }
  }, [inventory]);

  useEffect(() => {
    return () => {
      dispatch(resetInventory());
    };
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit} className="order">
      <h1 className="order--h1">{title}</h1>
      <section className="order--content--info">
        <section className="order--info">
          <Input
            autoFocus
            title="Código"
            type="number"
            value={order?.code}
            onChange={(e) => setOrder({ ...order, code: e.target.value })}
            onKeyPress={({ code }) => {
              if (code === "Enter" && disabledButtonEmptyValues(order)) {
                handleAddProduct();
              }
            }}
          />
          <Input
            title="Cantidad"
            type="number"
            value={order?.amount}
            onChange={(e) => {
              if (e.target.value < 0) {
                setMessage({
                  title: "Ingrese una cantidad mayor a 0",
                  severity: "error",
                });
              } else {
                setMessage({});
                setOrder({ ...order, amount: e.target.value });
              }
            }}
          />
        </section>
        <section className="order--content--button">
          <section className="order--content--button2">
            <Button
              label="Agregar producto"
              typeButton="outlined"
              iconName="PlusCircle"
              color="success"
              type="submit"
              disabled={disabledButtonEmptyValues(order)}
              onClick={() => handleAddProduct()}
            />
            <Button
              label="Guardar orden"
              typeButton="outlined"
              iconName="Save"
              color="success"
              disabled={
                isLoading ||
                products.length === 0 ||
                products.some(
                  (product) => product.amountOrder === 0 || !product.amountOrder
                )
              }
              onClick={() => handleSaveOrder()}
            />
          </section>
          <section className="order--content--button2">
            <Button
              label="Cancelar"
              typeButton="outlined"
              iconName="XCircle"
              color="error"
              iconColor="red"
              disabled={products.length === 0}
              onClick={() => handleCancel()}
            />
          </section>
        </section>
      </section>
      <Message severity={message.severity} label={message.title} />
      {products.length > 0 ? (
        isLoading ? (
          <section className="order--circularprogress">
            <CircularProgress />
            <p>Cargando...</p>
          </section>
        ) : (
          <OrderTable
            products={products}
            onDeleteProduct={handleDelete}
            onProductChange={handleProductChange}
            setTotalValue={setTotalValue}
            setMessage={setMessage}
          />
        )
      ) : (
        <></>
      )}
      {showOrderModal && (
        <OrderNumber
          title="Número de orden"
          opened={showOrderModal}
          orderNumber={idOrder}
          onClick={() => setShowOrderModal(false)}
          user={user}
          setChildren={setChildren}
        ></OrderNumber>
      )}
    </form>
  );
};
