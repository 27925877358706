import React, { useState } from "react";
import { Button } from "../../atoms/index";
import { pages, panelButtons } from "../../constans/constants";
import "./panel.style.scss";

export const Panel = ({ setChildren, activeButton, user }) => {
  const [menu, setMenu] = useState(false);
  const toggleMenu = () => {
    setMenu(!menu);
  };

  const handleClick = (children) => {
    setChildren(children);
  };

  return (
    <section className="panel">
      <Button
        classes="panel--button"
        label="Menu"
        typeButton="outlined"
        iconName="Menu"
        color="success"
        onClick={toggleMenu}
      />
      <section className={`panel--nav ${menu ? "isActive" : "#FFFFFF"}`}>
        <ul className="panel--ul">
          {panelButtons.map(({ label, icon, name, profile }) => {
            return profile.map((prof) => {
              if (prof === user.attributes.profile) {
                return (
                  <li className="panel--li" key={name}>
                    <Button
                      label={label}
                      typeButton={
                        activeButton === pages[name] ? "contained" : "outlined"
                      }
                      name={name}
                      iconName={icon}
                      iconColor={
                        activeButton === pages[name] ? "#FFFFFF" : "#2e7d32"
                      }
                      color="success"
                      onClick={(event) => handleClick(event.target.name)}
                    />
                  </li>
                );
              }
            });
          })}
        </ul>
      </section>
    </section>
  );
};
