import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button, Icon, Message } from "../../atoms/index";
import { resetBill } from "../../store";
import { searchElement } from "../../utils";
import { BillTable } from "../bills/billTable";
import "./billsHistory.style.scss";

export const BillsHistory = ({ title, opened, onClick, onDeleteProduct }) => {
  const [date, setDate] = useState("");
  const [billsFilter, setBillsFilter] = useState([]);
  const [message, setMessage] = useState({ title: "", severity: "" });
  const { bills } = useSelector((state) => state.bill);
  const dispatch = useDispatch();

  const handleClose = async () => {
    onClick();
    dispatch(resetBill());
  };

  const handleConsult = async () => {
    setMessage({});
    const getBills = searchElement({
      code: date,
      items: bills,
      key: "createDate",
    });
    if (getBills.length === 0) {
      setMessage({
        title: "No hay gastos para esa fecha",
        severity: "error",
      });
    }
    setBillsFilter(getBills);
  };

  return (
    <Modal open={opened} className="ModalBills">
      <section className="ModalBills--container">
        <section className="ModalBills--container--title">
          <h1>{title}</h1>
          <span
            className="ModalBills--container--icon-back"
            onClick={handleClose}
          >
            <Icon color="#FFFFFF" name="X"></Icon>
          </span>
        </section>
        <hr />
        <section className="ModalBills--container--content--info">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD/MM/YYYY"
              onChange={(date) =>
                setDate(`${date.$D}/${date.$M + 1}/${date.$y}`)
              }
            />
          </LocalizationProvider>
          <Button
            label="Consultar"
            typeButton="outlined"
            iconName="Search"
            color="success"
            onClick={handleConsult}
          />
        </section>
        <Message severity={message.severity} label={message.title} />
        <section className="ModalBills--container--content--table">
          {billsFilter.length > 0 ? <BillTable bills={billsFilter} /> : <></>}
        </section>
      </section>
    </Modal>
  );
};
