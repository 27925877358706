import { getInventoriesService } from "../../../services/inventory";
import {
  errorInventoryData,
  setInventoryData,
  startLoadingInventoryData,
} from "./inventorySlice";

export const getInventories = ()  => {
  return async (dispatch) => {
    dispatch(startLoadingInventoryData());
    await getInventoriesService()
      .then((inventory) => {
        dispatch(setInventoryData(inventory));
      })
      .catch((error) => {
        dispatch(errorInventoryData(error));
      });
  };
};
