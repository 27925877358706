import axios from "axios";

export const addBillService = async ({ seller, valueBill, detailBill }) => {
  const URL = "https://uef6z41imf.execute-api.us-east-1.amazonaws.com/v1/bill";
  const result = await axios.post(URL, {
    seller,
    valueBill,
    detailBill,
  });
  return result.data.status;
};
