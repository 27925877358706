import React, { useEffect, useState } from "react";
import { Input, Button, Message } from "../../atoms/index";
import { BillsHistory } from "../index";
import "./bills.style.scss";
import { disabledButtonEmptyValues } from "../../utils";
import {
  addBillService,
  deleteBillService,
  getBillsService,
} from "../../services/bill";
import { BillTable } from "./billTable";
import { useDispatch } from "react-redux";
import { getBills } from "../../store";
import { NumericFormat } from "react-number-format";

const initialState = {
  valueBill: "",
  detailBill: "",
};

export const Bills = ({ title, user }) => {
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [bill, setBill] = useState(initialState);
  const [bills, setBills] = useState(initialState);
  const [message, setMessage] = useState({});
  const dispatch = useDispatch();

  const handleSave = async () => {
    setMessage({});
    const response = await addBillService({
      seller: user.attributes.email,
      valueBill: bill.valueBill,
      detailBill: bill.detailBill,
    });
    if (response === 200) {
      setMessage({
        title: "Gasto guardado correctamente",
        severity: "success",
      });
    } else {
      setMessage({
        title: "No fue posible guardar el gasto",
        severity: "error",
      });
    }
    setBill(initialState);
  };

  const handleDeleteBill = async (bill) => {
    setMessage({});
    const result = await deleteBillService(bill.id);
    if (result === 200) {
      setMessage({
        title: "Gasto eliminado correctamente",
        severity: "success",
      });
    } else {
      setMessage({
        title: "No fue posible eliminar el gasto",
        severity: "error",
      });
    }
  };

  const handleHistoryBill = async () => {
    setShowHistoryModal(true);
    dispatch(getBills());
  };

  useEffect(() => {
    const loadBill = async () => {
      setBills(await getBillsService());
    };
    loadBill();
  }, [bills]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit} className="bills">
      <h1 className="bills--h1">{title}</h1>
      <section className="bills--content--info">
        <section className="bills--info">
          <Input
            title="Vendedor"
            value={user?.attributes?.email}
            disabled
            type="text"
          />
          <NumericFormat
            thousandSeparator
            customInput={Input}
            autoFocus
            title="Valor"
            value={bill?.valueBill}
            onValueChange={(values) =>
              setBill({ ...bill, valueBill: values.floatValue })
            }
          />
        </section>
        <section className="bills--info textarea">
          <Input
            title="Detalle"
            type="text"
            multiline
            rows="3"
            value={bill.detailBill}
            onChange={(e) => setBill({ ...bill, detailBill: e.target.value })}
          />
        </section>
        <section className="bills--content--button">
          <Button
            label="Historial"
            typeButton="outlined"
            iconName="List"
            color="success"
            onClick={() => handleHistoryBill()}
          />
          <Button
            label="Guardar"
            typeButton="outlined"
            iconName="Save"
            color="success"
            type="submit"
            disabled={disabledButtonEmptyValues(bill)}
            onClick={() => handleSave()}
          />
        </section>
        <Message severity={message.severity} label={message.title} />
        {bills.length > 0 ? (
          <BillTable bills={bills} onDeleteProduct={handleDeleteBill} />
        ) : (
          <></>
        )}
      </section>
      {showHistoryModal && (
        <BillsHistory
          title="Historial de gastos"
          opened={showHistoryModal}
          onClick={() => setShowHistoryModal(false)}
          onDeleteProduct={handleDeleteBill}
        ></BillsHistory>
      )}
    </form>
  );
};
