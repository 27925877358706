import { configureStore } from "@reduxjs/toolkit";
import { billSlice, inventorySlice, orderSlice } from "./index";

const store = configureStore({
  reducer: {
    inventory: inventorySlice.reducer,
    bill: billSlice.reducer,
    order: orderSlice.reducer,
  },
});

export default store;
