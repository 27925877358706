import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Message, Print, Select } from "../../atoms/index";
import { getOrderService, updateOrderService } from "../../services/order";
import { setNumberActualOrder } from "../../store";
import { disabledButtonEmptyValues, format } from "../../utils";
import { SalesHistory } from "../index";
import CircularProgress from "@mui/material/CircularProgress";
import { SaleTable } from "./saleTable";
import "./sales.style.scss";

const initialState = {
  id: "",
  seller: "",
  stateOrder: "",
  paymentMethod: "",
  valueReceived: "",
};

export const Sales = ({ title }) => {
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showBillModal, setShowBillModal] = useState(false);
  const [sales, setSales] = useState(initialState);
  const [order, setOrder] = useState(initialState);
  const [message, setMessage] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const dispatch = useDispatch();

  const { actualOrder } = useSelector((state) => state.order);

  useEffect(() => {
    if (actualOrder) {
      handleGet(actualOrder);
      dispatch(setNumberActualOrder(undefined));
    }
  }, [actualOrder, dispatch]);

  const handleGet = async (id) => {
    const result = await getOrderService({ id });
    if (result.errorDetail) {
      setMessage({
        title: "Venta no encontrada",
        severity: "error",
      });
      setOrder(initialState);
      setSales(initialState);
    } else {
      setSales({ ...sales, valueReceived: result.totalOrder });
      setOrder(result);
    }
  };

  const initState = () => {
    setOrder(initialState);
    setSales(initialState);
  };

  const handleCancelPayOrder = async (
    id,
    products,
    paymentMethod,
    stateOrder
  ) => {
    setMessage({});
    try {
      setIsloading(true);
      const response = await updateOrderService({
        id,
        paymentMethod,
        stateOrder,
        products,
      });

      if (response === 200) {
        if (stateOrder === "CANCELADA") {
          setMessage({
            title: "Venta CANCELADA correctamente",
            severity: "success",
          });
          initState();
        } else if (stateOrder === "PAGADA") {
          setShowBillModal(true);
        }
      } else {
        setMessage({
          title: "No fue posible tramitar la venta",
          severity: "error",
        });
      }
    } catch (error) {
      setMessage({
        title: "Error en el servicio: No fue posible tramitar la venta",
        severity: "error",
      });
    } finally {
      setIsloading(false);
    }
  };

  const handleHistoryBill = async () => {
    setShowHistoryModal(true);
  };

  const onDetailProduct = (product) => {
    handleGet(product?.id);
    setShowHistoryModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit} className="sales">
      <h1 className="sales--h1">{title}</h1>
      <section className="sales--content--info">
        <section className="sales--info">
          <Input
            autoFocus
            title="Código"
            type="text"
            value={sales?.id || order?.id}
            onChange={(e) => setSales({ ...sales, id: e.target.value })}
          />
          <Input title="Vendedor" disabled type="text" value={order?.seller} />
          <Input
            title="Estado"
            disabled
            type="text"
            value={order?.stateOrder}
          />
          {order.paymentMethod ? (
            <Input
              title="Metodo de pago"
              disabled
              type="text"
              value={order.paymentMethod}
            />
          ) : (
            <Select
              title="Metodo de pago"
              name="select-payment-method"
              options={[
                { value: "Efectivo", label: "EFECTIVO" },
                { value: "Transferencia", label: "TRANSFERENCIA" },
              ]}
              value={sales.paymentMethod}
              handleChange={(e) =>
                setSales({ ...sales, paymentMethod: e.target.value })
              }
            />
          )}
          <NumericFormat
            thousandSeparator
            customInput={Input}
            title="Valor recibido"
            value={sales.valueReceived}
            onValueChange={(values) =>
              setSales({ ...sales, valueReceived: values.floatValue })
            }
          />
        </section>
        <section className="sales--info">
          <Input
            title="Devolver"
            type="text"
            value={
              "$" +
              format.format(
                sales.valueReceived ? sales.valueReceived - order.totalOrder : 0
              )
            }
            inputProps={{
              style: {
                color: "green",
                fontSize: "2rem",
              },
            }}
          />
        </section>
        <section className="sales--content--button">
          <section className="sales--content--button2">
            <Button
              label="Historial"
              typeButton="outlined"
              iconName="List"
              color="success"
              onClick={() => handleHistoryBill()}
            />
            <Button
              label="Consultar"
              typeButton="outlined"
              iconName="Search"
              color="success"
              type="submit"
              disabled={disabledButtonEmptyValues({
                code: sales?.id,
              })}
              onClick={() => handleGet(sales?.id)}
            />
          </section>
          <section className="sales--content--button2">
            <Button
              label="Pagar"
              typeButton="outlined"
              iconName="DollarSign"
              color="success"
              disabled={
                isLoading ||
                !order.products ||
                sales.paymentMethod === "" ||
                order.stateOrder !== "CREADA" ||
                sales.valueReceived === undefined ||
                (sales.valueReceived - order.totalOrder !== 0 &&
                  sales.valueReceived - order.totalOrder <= 0)
              }
              onClick={() =>
                handleCancelPayOrder(
                  sales?.id || order?.id,
                  "",
                  sales?.paymentMethod,
                  "PAGADA"
                )
              }
            />
            <Button
              label="Cancelar"
              typeButton="outlined"
              iconName="XCircle"
              color="error"
              iconColor="red"
              disabled={
                isLoading || !order.products || order.stateOrder !== "CREADA"
              }
              onClick={() =>
                handleCancelPayOrder(
                  sales?.id || order?.id,
                  order?.products,
                  "",
                  "CANCELADA"
                )
              }
            />
          </section>
        </section>
        <Message severity={message.severity} label={message.title} />
        {order.products ? (
          isLoading ? (
            <section className="sales--circularprogress">
              <CircularProgress />
              <p>Cargando...</p>
            </section>
          ) : (
            <SaleTable
              products={order.products}
              setTotalValue={order.totalOrder}
            />
          )
        ) : (
          <></>
        )}
      </section>

      {showHistoryModal && (
        <SalesHistory
          title="Historial de ventas"
          opened={showHistoryModal}
          onClick={() => setShowHistoryModal(false)}
          onDetailProduct={onDetailProduct}
        ></SalesHistory>
      )}

      {showBillModal && (
        <Print
          order={order}
          sales={sales}
          opened={showBillModal}
          onClick={() => {
            setShowBillModal(false);
            initState();
          }}
        />
      )}
    </form>
  );
};
